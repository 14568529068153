import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/core/services/event.service';
import { EntryService } from 'src/app/core/services/entry.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  classArr: any = [];
  sidepotArr: any = [];
  sidepotEntries: any = [];
  eventId: any;
  searchField: any = "";

  reports: any = [
    {
      tag: "Book Keeping",
      name: "All Entries",
      reportName: 'allEntries',
      description: "This will export all entries weather they are only submitted or marked paid."
    },
    {
      tag: "Book Keeping",
      name: "Purchase Report",
      reportName: 'purchaseReport',
      description: "This will generate a pdf report that containers an itemized receipt of every entry collected"
    },
    {
      tag: "Billing",
      name: "Paysheet",
      reportName: 'paysheetAll',
      description: "This will export a report that lists contestant information and the amount they owe."
    },
    {
      tag: "Billing",
      name: "Paysheet grouped by class",
      reportName: 'exportUnpaidBySidePot',
      description: "This will export a report that creates tabs for each class and displays the amount due per contestant."
    },
    {
      tag: "Showtime",
      name: "Sidepots grouped by class",
      reportName: 'sidepotsGroupedByClass',
      description: "This will export a report that creates tabs for each class then groups entries by sidepot."
    },
    // {
    //   tag: "Showtime",
    //   name: "Class Draws",
    //   description: "This will export a report that that has a tab for each class and randomizes each classes entries."
    // },
    {
      tag: "Event Management",
      name: "Additional Items",
      reportName: 'additionalItems',
      description: "This will export a report that displayes additional items purchased (Stalls, Electric Hookups...)"
    },
  ];

  constructor(private route: ActivatedRoute, private entry: EntryService, private event: EventService, private excelService: ExcelService) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.eventId = routeParams.get('id');
    this.event.getClasses(this.eventId).subscribe(
      response => {
        this.classArr = response;
      },
      err => console.log(err)
    );


    this.entry.getAllSidePotEntrys(this.eventId).subscribe(
      response => {
        this.sidepotEntries = response;
      },
      err => console.log(err)
    );


  }

  openReport(name: string) {
    console.log(name);
    switch (name) {
      case 'allEntries':
        return this.allEntries();
      case 'purchaseReport':
        return this.purchaseReport();
      case 'sidepotsGroupedByClass':
        return this.sidepotsGroupedByClass();
      case 'exportUnpaidBySidePot':
        return this.exportUnpaidBySidePot();
      case 'additionalItems':
        return this.additionalItems();
        case 'paysheetAll':
          return this.paysheetAll();
    }
  }

  paysheetAll(): void {

    this.entry.getMoneyDue(this.eventId).subscribe(
      response => {
        let result: any = [];

        for (let y = 0; y < response.length; y++) {

          let entryObj = {
            "Name": response[y]['name'],
            "Email": response[y]['email'],
            "Phone": response[y]['phone'],
            "address": response[y]['address'],
            "Amount Due": response[y]['amountPaid'] / 100,
            "Paid": "N",
            "Notes": response[y]['notes']
          }

          result.push(entryObj);

        }

        this.excelService.exportAsExcelFile(["Cash entries"], [result], 'entries');


      },
      err => console.log(err)
    );



  }
  

  additionalItems() {
    this.event.getAdditionalItems(this.eventId).subscribe(
      additionalItems => {

        //Class Name
        var itemNames: any = [];
        var result: any = {};

        //@ts-ignore
        for (let x = 0; x < additionalItems.length; x++) {
          //@ts-ignore
          result[additionalItems[x]['_id']] = [];

          //@ts-ignore
          itemNames.push(additionalItems[x]['name']);
        }

        this.entry.getAllAdditionalItemPurchases(this.eventId).subscribe(
          response => {

            for (let y = 0; y < response.length; y++) {

              //@ts-ignore
              if (response[y]['entry']) {

                let entryObj: any = {
                  //@ts-ignore
                  "Name": response[y]['entry']['name'],
                  //@ts-ignore
                  "Quantity": response[y]['amount'],
                  //@ts-ignore
                  "Notes": response[y]['entry']['notes']
                }

                //@ts-ignore
                result[response[y]['item']['_id']].push(entryObj);

              }

            }

            this.excelService.itemExport(itemNames, Object.values(result), 'purchased_items');


          },
          err => console.log(err)
        );



      },
      err => console.log(err)
    );


  }

  purchaseReport() {
    this.entry.purchaseSummary(this.eventId).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL(response);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "purchaseReport.pdf";
        link.click();
      });
  }

  exportUnpaidBySidePot(): void {

    this.event.getClasses(this.eventId).subscribe(
      classes => {

        //Class Name
        var classNames: any = [];
        var result: any = {};

        //@ts-ignore
        for (let x = 0; x < classes.length; x++) {
          //@ts-ignore
          result[classes[x]['_id']] = [];

          //@ts-ignore
          classNames.push(classes[x]['name']);
        }

        this.entry.getMoneyDueBySidePot(this.eventId).subscribe(
          response => {

            for (const [key, value] of Object.entries(response)) {

              //@ts-ignore
              for (let y = 0; y < value.length; y++) {

                // let entryObj = {
                //   "Name": value[y]['name'],
                //   "Email": value[y]['email'],
                //   "Phone": value[y]['phone'],
                //   "address": value[y]['address'],
                //   "Amount Due": value[y]['amountDue']['total'],
                //   "Paid": "N",
                //   "Notes": value[y]['notes']
                // }


                let entryObj = {
                  "Name": value[y]['name'],
                  "Phone": value[y]['phone'],
                  "Amount Due": value[y]['amountDue'],
                  "Notes": value[y]['notes'],
                }

                result[key].push(entryObj);

              }

            }

            this.excelService.unpaidSidePotExport(classNames, Object.values(result), 'cash_entries_by_sidepot');


          },
          err => console.log(err)
        );



      },
      err => console.log(err)
    );


  }


  getSidePotName(id: any) {
    let name = "";

    for (let i = 0; i < this.classArr.length; i++) {

      for (let x = 0; x < this.classArr[i]['sidePots'].length; x++) {
        let sidepot = this.classArr[i]['sidePots'][x];
        if (sidepot['_id'] === id) {
          name = sidepot['name'];
        }

      }
    }

    return name;
  }

  sidepotsGroupedByClass() {
    let classNames: any = [];
    let classIds: any = [];
    let classData: any = {};

    this.entry.getAllSidePotEntrys(this.eventId).subscribe(
      response => {

        let entries = response;
        for (let i = 0; i < this.classArr.length; i++) {

          classNames.push(this.classArr[i].name);
          classIds.push(this.classArr[i]['_id']);

          for (let a = 0; a < classIds.length; a++) {
            classData[classIds[a]] = [];
          }

        }


        for (let y = 0; y < entries.length; y++) {
          let entry: any = {};
          entry = entries[y];
          let entryObj = {};

          try {

            entryObj = {
              "Name": this.getSidePotName(entry['sidePot']),
              "Rider": entry['entry']['name'],
              "Horse": entry['name'],
              "Time": "",
              "Place": "",
              "Points": ""
            }

          } catch (err) {
            console.error(y, entry);
          }

          classData[entry['class']].push(entryObj);

        }

        let res = [];

        //Loop Classes
        for (let i = 0; i < this.classArr.length; i++) {

          if (classData[this.classArr[i]['_id']]) {

            let data = {};

            //loop sidepots
            for (let s = 0; s < this.classArr[i]['sidePots'].length; s++) {
              let sidepotName = this.classArr[i]['sidePots'][s]['name'];
              //Loop sidepot entry that belongs in this sidepot group
              for (let x = 0; x < classData[this.classArr[i]['_id']].length; x++) {
                let item = classData[this.classArr[i]['_id']][x];
                if (sidepotName === item['Name']) {
                  //@ts-ignore

                  if (!data[sidepotName]) {
                    //@ts-ignore
                    data[sidepotName] = [];
                  }

                  //@ts-ignore
                  data[sidepotName].push(item);
                }

              }

              //@ts-ignore
              if (!data[sidepotName]) {
                //@ts-ignore
                data[sidepotName] = [];
              }

              //@ts-ignore
              data[sidepotName].push({
                "Horse": "",
                "Name": "",
                "Place": "",
                "Points": "",
                "Rider": "",
                "Time": ""
              }, {
                "Horse": "",
                "Name": "",
                "Place": "",
                "Points": "",
                "Rider": "",
                "Time": ""
              }, {
                "Horse": "",
                "Name": "",
                "Place": "",
                "Points": "",
                "Rider": "",
                "Time": ""
              });



            }

            res.push(data);

          }


        }

        this.excelService.sidePotExport(classNames, res, 'sidepots');

      },
      err => console.log(err)
    );


  }

  allEntries() {
    this.entry.allPopClasses(this.eventId).subscribe(
      response => {
        let entries = response;

        this.event.getClasses(this.eventId).subscribe(
          classArrRes => {

            let classArr: any = [];
            let classIdDate: any = [];
            let classData: any = {};
            classArr = classArrRes;

            let classNames: any = [];

            var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];


            for (let i = 0; i < classArr.length; i++) {
              for (let x = 0; x < classArr[i]['prices'].length; x++) {

                let currentDate = classArr[i]['prices'][x]['date'];
                let d = new Date(currentDate);
                let dayName = days[d.getDay() + 1];

                if (dayName === undefined) {
                  dayName = 'Sunday'
                }

                //@ts-ignore
                classNames.push(classArr[i].name + ' ' + dayName);
                classIdDate.push(classArr[i]['_id'] + "_" + classArr[i]['prices'][x]['date']);
              }

            }

            for (let a = 0; a < classIdDate.length; a++) {
              classData[classIdDate[a]] = [];
            }

            for (let y = 0; y < entries.length; y++) {
              let entry: any = {};
              entry = entries[y];

              for (let z = 0; z < entry['classes'].length; z++) {

                let entryClassObj: any = {};
                entryClassObj = entry['classes'][z];
         
                let entryObj = {
                  "Draw": "",
                  "Rider": entry['name'],
                  "Horse": entryClassObj['name'],
                  "Rollover": entryClassObj['rollover'] ? entryClassObj['rollover'] : '',
                  "District": entry['district'],
                  "Member #": entry['memberNumber'],
                  "Time": ""
                }

          
                classData[entryClassObj['class']['_id'] + "_" + entryClassObj['date']].push(entryObj);


              }


            }


            this.excelService.exportAsExcelFile(classNames, Object.values(classData), 'classes');

          },
          err => console.log(err)
        );




      },
      err => console.log(err)
    );

  }

}
